<template>
    <v-container>
        <v-card width="260">
            <v-card-title>Assign</v-card-title>
            <v-card-text>
                <kurcc-autocomplete
                    :items="categories"
                    :loading="fetchingCategories"
                    :outlined="true"
                    :selected-items.sync="selectedCategories"
                    name="Category"
                    rules="">
                </kurcc-autocomplete>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "KurccAssignFormToCategories",
    components: {
        KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete')
    },
    props: {
        selectedCategoriesToAssignTo: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            categories: [],
            fetchingCategories: false,
            selectedCategories: this.selectedCategoriesToAssignTo
        }
    },
    watch: {
        selectedCategories: {
            handler: function (val) {
                this.$emit('update:selectedCategoriesToAssignTo', val)
            }
        }
    },
    methods: {
        getAllCategories() {
            this.fetchingCategories = true
            this.$store.dispatch('getAllCategories', {withIncludes: false})
                .then(res => {
                    this.categories = res.items
                }).finally(() => {
                    this.fetchingCategories = false
                }
            )
        },
    },
    created() {
        this.getAllCategories()
    }
}
</script>
